import React, { useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import jQuery from 'jquery';
import  '../pudo.css';
import DataTable from 'react-data-table-component'; // Import DataTable component
import DataTableExtensions from 'react-data-table-component-extensions'; // Import DataTableExtensions component
import 'react-data-table-component-extensions/dist/index.css';

export default function EditUer() {
    const url=window.location.href;
    const getuser_id = url.split("/").pop();
    const [userdata, setuserdata] = useState({password:'',
        status:'1'}
    )
 
    console.log(userdata)
    return (
        <div>
            <Header />
            <header>
                <h1 className="site-heading text-center text-faded d-none d-lg-block">
                    <span className="site-heading-upper text-primary mb-3">PUDO</span>
                </h1>
            </header>
            <section className="page-section cta">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-9 mx-auto">
                            <div className="cta-inner bg-faded text-center rounded">
                                <h2 className="section-heading mb-5">
                                    
                                    <span className="section-heading-lower">Change Password</span>
                                    
                                </h2>
                            
                              <div className="form-group row">
  <div className="col-md-6">
    <input type="text" className="form-control"  onChange={(e)=>setuserdata({...userdata,password:e.target.value})} placeholder="New Password" />
  </div>
  <div className="col-md-6">
  <select className="form-control"  onChange={(e)=>setuserdata({...userdata,status:e.target.value})}>
    <option value="1">Active</option>
    <option value="0">1nactive</option>
  </select>
  </div>
  
</div>
                            <br></br>
                                <button className='button'>Change Password</button>
                            </div>
                          
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}
