import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import jQuery from 'jquery';
import '../pudo.css';
import DataTable from 'react-data-table-component'; // Import DataTable component
import DataTableExtensions from 'react-data-table-component-extensions'; // Import DataTableExtensions component
import 'react-data-table-component-extensions/dist/index.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function Login() {
   
    const [userdata, setuserdata] = useState({
        password: '',
        username: ''
    }
    )

    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  
    const navigate=useNavigate()
    const [msg, setmsg] = useState()
    const [dashbaord, setdashboard] = useState(0)
    const API_PATH = `${SERVER_URL}login.php`;
    function Submitlogin(){
        axios({
            method:'post',
            url:`${API_PATH}`,
            data:userdata
        }).then((res)=>{
          if(res.data.status==1){
            sessionStorage.setItem('userid',res.data.userid)
            sessionStorage.setItem('role',res.data.role)
            sessionStorage.setItem('shipperref',res.data.shipperref)
          
            setmsg(res.data.message)
            setdashboard(1)
          }else{
            setmsg(res.data.message)
          }
        
   
      
      
        }).catch((err)=>console.log(err))
    }

    useEffect(() => {
      if(dashbaord==1){
        window.location.replace('/home')
        // navigate('dashboard')
      }
      
    }, [dashbaord])

    console.log(userdata)
    return (
        <div>
            <Header />
           
            <section className="page-section cta">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-9 mx-auto">
                            <div className="cta-inner bg-faded text-center rounded">
                                <h2 className="section-heading mb-5">

                                    <span className="section-heading-lower">Login</span>

                                </h2>
                                <div className="form-group row">
                                    <div className="col-md-6 offset-md-3">
                                        <input type="text" className="form-control" onChange={(e) => setuserdata({ ...userdata, username: e.target.value })} placeholder="Username" />
                                    </div>


                                </div><br/>
                                <div className="form-group row">
                                    <div className="col-md-6 offset-md-3">
                                        <input type="password" className="form-control" onChange={(e) => setuserdata({ ...userdata, password: e.target.value })} placeholder="Password" />
                                    </div>


                                </div>

                                
                                <br></br>
                                <p>{msg}</p>
                                <button className='button' onClick={Submitlogin}>Login</button>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}
