import React, { useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import jQuery from 'jquery';
import  '../pudo.css';
import DataTable from 'react-data-table-component'; // Import DataTable component
import DataTableExtensions from 'react-data-table-component-extensions'; // Import DataTableExtensions component
import 'react-data-table-component-extensions/dist/index.css';
import axios from 'axios';

export default function Pickup() {

    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const $ = jQuery.noConflict();
    const [orderdata, setorderdata] = useState({
        awb: '',
        phone: ''
    }
    )

    const [cod, setcod] = useState(0)
    const [isload, setisload] = useState(true);
    const [data, setData] = useState([]);
    const [errormsg, seterrormsg] = useState('')
    const [msg, setmsg] = useState('')
   function searchOrder(){ 
    seterrormsg('')
    const API_PATH = `${SERVER_URL}orders.php`;
    const passval = {
        type: "get_order",
        orderreq: orderdata
    };
     axios.post(API_PATH, passval)   
        .then(res => {
           console.log(' data is: ',res.data)
           if(res.data==1){
            seterrormsg('Shipment Already delivered')
           }else{
           if (res.data.consignee === null){
            seterrormsg('AWB or phone number is wrong')
           }else{
            setData(res.data)
            setisload(false); 
           }
        }
                          
        })
        .catch((err) => {
            console.log(err);
        }) 
   }

   function confirmPickup(cons){
    const API_PATH = `${SERVER_URL}orders.php`;
    if(data.codAmount >0 && cod==0){
        alert('please make sure to collect the COD')
    }else{
    const passval = {
        type: "confirm_pickup",
        orderreq: orderdata,
        cod:cod,
        consignee:cons,
        user:  sessionStorage.getItem('userid')
    };
     axios.post(API_PATH, passval)   
        .then(res => {
           if(res.data==1){
            setData([])
            setmsg("Delivery Completed")
           }else{
            seterrormsg("Error completing delivery")
           }
            setisload(false);                
        })
        .catch((err) => {
            console.log(err);
        }) 
   }
}

   console.log('...: ',data)
    return (
        <div>
            <Header />
          
            <section className="page-section cta">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-9 mx-auto">
                            <div className="cta-inner bg-faded text-center rounded">
                                <h2 className="section-heading mb-5">
                                    
                                    <span className="section-heading-lower">Pick Up Parcel</span>
                       
                                </h2>
                            
                              <div className="form-group row">
  <div className="col-md-6">
    <input type="text" className="form-control" onChange={(e) => setorderdata({ ...orderdata, awb: e.target.value })}  placeholder="Enter AWB" />
  </div>
  <div className="col-md-6">
    <input type="text" className="form-control" onChange={(e) => setorderdata({ ...orderdata, phone: e.target.value })} placeholder="Last 4 digits of Mobile" />
  </div>
</div>
                            <br></br>
                                <button className='button' onClick={searchOrder}>Search Order</button>
                                <p style={{color:'red'}}>{errormsg}</p>
                                <p style={{color:'green'}}>{msg}</p>
                                <div className="details-section mt-5">
                             { Object.keys(data).length>0?(
<>
 <h3 className="section-heading mb-4">Order Details</h3>
 {/* First Row */}
 <div className="row">
                     <div className="col-md-6">
                         <div className="detail-item">
                             <h5>Shipper Details:</h5>
                             <div className='blockcls'>
                             <p id="shipperDetails">{data.shipContPerson}</p>
                             <p>{data.shipAdd1}, {data.shipAdd2}</p>
                             <p>{data.shipCity}, {data.origin}</p>
                             <p>{data.shipFax}</p>
                             </div>
                         </div>
                     </div>
                     <div className="col-md-6">
                         <div className="detail-item">
                             <h5>Consignee Details:</h5>
                             <div className='blockcls'>
                             <p id="consigneeDetails">{data.consignee}</p>
                             <p>{data.consigneeAddress1}, {data.consigneeAddress2}</p>
                             <p>{data.consigneeCity}, {data.destination}</p>
                             <p>{data.consigneePhone}</p>
                         </div>
                         </div>
                     </div>
                 </div>

                 {/* Second Row */}
                 <div className="row">
                     <div className="col-md-6">
                         <div className="detail-item">
                             <h5>Reference:</h5>
                             <div className='blockcls'>
                             <p id="reference"> {data.shipperRef}</p>
                         </div>
                         </div>
                     </div>
                     <div className="col-md-6">
                         <div className="detail-item">
                             <h5>Account Number:</h5>
                             <div className='blockcls'>
                             <p id="accountNumber">11454</p>
                         </div>
                         </div>
                     </div>
                 </div>

                 {/* Third Row */}
                 <div className="row">
                     <div className="col-md-6">
                         <div className="detail-item">
                             <h5>Weight:</h5>
                             <div className='blockcls'>
                             <p id="weight">{data.weight}</p>
                         </div>
                         </div>
                     </div>
                     <div className="col-md-6">
                         <div className="detail-item">
                             <h5>Special Instruction:</h5>
                             <div className='blockcls'>
                             <p id="specialInstruction">{data.goodDescription}</p>
                         </div>
                         </div>
                     </div>
                 </div>
 

                 <div className="row">
                 <div className="col-md-6">
 <div classname="detail-item">
   <h5>COD:</h5>
   <div className='blockcls'>
   <p id="cod">{data.codAmount}</p>

 </div>
 </div>
</div>
{data.codAmount >0?(
    <div className="col-md-6">
 <div classname="detail-item">
   <h5>Enter COD Received</h5>
   <div className='blockcls'>
  <input type="text" className='form-control' onChange={(e)=>setcod(e.target.value)}/>

 </div>
 </div>
</div>

):null} 

</div>
<div className="row">
<div className="col-md-6"></div>
<div className="col-md-6">
<div classname="detail-item">
<h5>&nbsp;</h5>
<button className='button' style={{marginBottom:'6%'}} onClick={() => confirmPickup(data.consignee)}>Confirm Pickup</button>

</div>
</div>

</div>


</>
                             ):null}   
                           
                           </div>

                            </div>




                          
                        </div>


                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}
