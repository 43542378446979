import React, { useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import jQuery from 'jquery';
import '../pudo.css';
import DataTable from 'react-data-table-component'; // Import DataTable component
import DataTableExtensions from 'react-data-table-component-extensions'; // Import DataTableExtensions component
import 'react-data-table-component-extensions/dist/index.css';
import axios from 'axios';

export default function Profile() {
    const url = window.location.href;
    const getuser_id = url.split("/").pop();
    const [userdata, setuserdata] = useState({
        password: '',
        status: '1',
        userid: getuser_id,
        type: "update_user"
    }
    )

    const [msg, setmsg] = useState('')
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;

    const API_PATH = `${SERVER_URL}user.php`;
    function updateDetails() {
        axios({
            method: 'post',
            url: `${API_PATH}`,
            data: userdata
        }).then((res) => {
           setmsg(res.data)

           

        }).catch((err) => console.log(err))
    }
    console.log(userdata)
    return (
        <div>
            <Header />

            <section className="page-section cta">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-9 mx-auto">
                            <div className="cta-inner bg-faded text-center rounded">
                                <h2 className="section-heading mb-5">

                                    <span className="section-heading-lower">Update Details</span>

                                </h2>

                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label>Password</label>
                                        <input type="text" className="form-control" onChange={(e) => setuserdata({ ...userdata, password: e.target.value })} placeholder="New Password" />
                                    </div>
                                    <div className="col-md-6">
                                        <label>Store Status</label>
                                        <select className="form-control" onChange={(e) => setuserdata({ ...userdata, status: e.target.value })}>
                                            <option value="1">Active</option>
                                            <option value="0">1nactive</option>
                                        </select>
                                    </div>

                                </div>
                                <br></br>
                                <p>{msg}</p>
                                <button className='button' onClick={updateDetails}>Update Details</button>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}
