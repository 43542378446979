import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import jQuery from 'jquery';
import  '../pudo.css';
import DataTable from 'react-data-table-component'; // Import DataTable component
import DataTableExtensions from 'react-data-table-component-extensions'; // Import DataTableExtensions component
import 'react-data-table-component-extensions/dist/index.css';
import axios from 'axios';
import { Link } from 'react-router-dom';

export default function Users() {

    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const $ = jQuery.noConflict();

    useEffect(() => {
        getUserList();
    }, []);

    const [isload, setisload] = useState(true);
    const [data, setdata2] = useState([]);
    const getUserList = async () => {
        const API_PATH = `${SERVER_URL}user.php`;
        const passval = {
            type: "userlist",
            user: "user"
        };
        await axios
            .post(API_PATH, passval)   
            .then(res => {
                // console.log(res);
                setdata2(res.data.list)
                setisload(false);                
            })
            .catch((err) => {
                console.log(err);
            }) 
    }


    function getDeleteuser(e){

alert(e)
    }
    const columns = [
        {
            name: "Name",
            selector:row => row.name,
            sortable: true,
        },

        {
            name: "Username",
            selector:row => row.username,
            sortable: true,
        },

        {
            name: "MSD_site",
            selector:row => row.MSD_site,
            sortable: true,
        },
        {
            name: "location",
            selector:row => row.location,
            sortable: true,
        },

        {
            name: "Actions",
            cell: (row) => <>
                <Link to={`/edit-user/${row.id}`}><span>edit</span></Link>
                {/* <a className="btn btn-danger" onClick={(e) => getDeleteuser(e.target.value)} value={row.id} style={{ color: '#fff' }}>Delete</a> */}
               <span> <a  value={row.id} onClick={(e) => getDeleteuser(e.target.attributes.getNamedItem("value").value)} style={{ padding: '6px 12px' }}>delete</a> </span>
            </>,
        },
    ]

    const tableData = {
      columns,
      data
    };


    console.log(tableData);
    return (
        <div>
            <Header />
            <header>
                <h1 className="site-heading text-center text-faded d-none d-lg-block">
                    <span className="site-heading-upper text-primary mb-3">PUDO</span>
                </h1>
            </header>
            <section className="page-section cta">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-9 mx-auto">
                            <div className="cta-inner bg-faded text-center rounded">
                                <h2 className="section-heading mb-5">
                                    
                                    <span className="section-heading-lower">Users</span>
                                    {/* <span className="section-heading-upper">13/06/2024</span> */}
                                </h2>
                                <DataTableExtensions {...tableData}>
                                    <DataTable
                                        columns={columns}
                                        data={data}
                                        noHeader
                                        defaultSortField="user"
                                        defaultSortAsc={true}
                                        pagination
                                        highlightOnHover
                                        dense
                                    />
                                </DataTableExtensions>

                            
                                <button className='button'>Confirm Receive</button>
                            </div>
                          
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}
