import React, { useState } from 'react'
import logo from '../../src/assets/img/1.png'
import { Link, useNavigate } from 'react-router-dom'
import '../pudo.css';
import '@fortawesome/fontawesome-free/css/all.css'; // Ensure you import the Font Awesome CSS
import CheckLogin from './CheckLogin';
export default function Header() {


  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const navigate = useNavigate()
  const handleLogout = () => {
    // Add logic for logout functionality
    // For example, clear local storage, redirect to login page, etc.
    console.log('Logging out...');
    sessionStorage.removeItem('userid');
    sessionStorage.removeItem('role');
    sessionStorage.removeItem('shipperref');
    // window.location.replace(`${location.state.payment_page}`)
    navigate('/')
  };


  return (
    <div>
      <CheckLogin />
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <meta name="description" content />
      <meta name="author" content />
      <title>Business Casual - Start Bootstrap Theme</title>
      <link rel="icon" type="image/x-icon" href="assets/favicon.ico" />
      {/* Google fonts*/}
      <link href="https://fonts.googleapis.com/css?family=Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i" rel="stylesheet" />
      <link href="https://fonts.googleapis.com/css?family=Lora:400,400i,700,700i" rel="stylesheet" />
      {/* Core theme CSS (includes Bootstrap)*/}
      <header>
        <h1 className="site-heading text-center text-faded d-none d-lg-block">
          <span className="site-heading-upper text-primary mb-3"><Link to="/home" style={{ textDecoration: 'none' }}><img src={logo} style={{ width: '20%' }} /></Link></span>
          {sessionStorage.getItem('userid') ? (
         <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-6">
          <span className="site-heading-upper text-primary mb-3"><Link to="/home" style={{textDecoration:'none',fontSize:'15px',float:'right',background:'#f3ab00',color:'#fff',padding:'3px'}}>Home</Link></span>

          </div>
         </div>
          ):null}
          
        </h1>
        {sessionStorage.getItem('userid') ? (
          <div className="profile-dropdown">
            <button className="profile-button" onClick={toggleDropdown}>
              <i className="fa fa-user"></i>     {sessionStorage.getItem('shipperref')}
            </button>
            {showDropdown && (
              <div className="dropdown-content">
                <Link to={`/profile/${sessionStorage.getItem('userid')}`} style={{ textDecoration: 'none' }}>Profile</Link>
                <a href="#" onClick={handleLogout}>Logout</a>
              </div>
            )}
          </div>
        ) : null}

      </header>
    </div>
  )
}
