import React from 'react'
import '../css/styles.css'
import Header from './Header'
import Footer from './Footer'
import { Link } from 'react-router-dom'
export default function Home() {
  return (
    <div>
    <Header/>
   

    <section className="page-section ">
      <div className="container">
        <div className="row">
        <div className="col-md-2" />
          <div className="col-md-3">
            <div className="intro1">
              <div className="intro-text left-0 text-center bg-faded p-5 rounded">
                <h2 className="section-heading mb-2">
                <span className="section-heading-upper">
                  <Link className="" to="/orders" style={{color:"#000",textDecoration:"none"}} >Receive Parcels
                  </Link>
                  </span>
                </h2>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="intro1">
              <div className="intro-text left-0 text-center bg-faded p-5 rounded">
                <h2 className="section-heading mb-2">
                <span className="section-heading-upper">
                  <Link className="" to="/pickup" style={{color:"#000",textDecoration:"none"}} >Pickup Parcel
                  </Link>
                  </span>
                </h2>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="intro1">
              <div className="intro-text left-0 text-center bg-faded p-5 rounded">
                <h2 className="section-heading mb-2">
                <span className="section-heading-upper">
                  <Link className="" to="/return" style={{color:"#000",textDecoration:"none"}} >Return Parcel
                  </Link>
                  </span>
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{marginTop: '3%'}}>
          <div className="col-md-2" />
          {/* <div className="col-md-4">
            <div className="intro1">
              <div className="intro-text left-0 text-center bg-faded p-5 rounded">
                <h2 className="section-heading mb-2">
                <span className="section-heading-upper">
                  <Link className="" to="/return" style={{color:"#000",textDecoration:"none"}} >Return Parcel
                  </Link>
                  </span>
                </h2>
              </div>
            </div>
          </div> */}
          {/* <div className="col-md-4">
            <div className="intro1">
              <div className="intro-text left-0 text-center bg-faded p-5 rounded">
                <h2 className="section-heading mb-2">
                  <span className="section-heading-upper">Drop off parcel</span>
                </h2>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  
  <Footer/>
  </div>
  )
}
