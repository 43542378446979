import logo from './logo.svg';
import './App.css';
import Home from './pages/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Orders from './pages/Orders';
import Pickup from './pages/Pickup';
import Return from './pages/Return';
import Users from './pages/Users';
import EditUer from './pages/EditUser';
import Login from './pages/Login';
import Profile from './pages/Profile';

function App() {
  return (
    <div className="App">
         <BrowserRouter>
    
      <Routes>
      <Route path="/" element={<Login/>}></Route>
      <Route path="/login" element={<Login/>}></Route>
      <Route path="/home" element={<Home/>}></Route>
      <Route path="/orders" element={<Orders/>}></Route>
      <Route path="/pickup" element={<Pickup/>}></Route>
      <Route path="/return" element={<Return/>}></Route>
      <Route path="/users" element={<Users/>}></Route>
      <Route path="/edit-user/*" element={<EditUer/>}></Route>
      <Route path="/profile/*" element={<Profile/>}></Route>
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
