import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import jQuery from 'jquery';
import  '../pudo.css';
import DataTable from 'react-data-table-component'; // Import DataTable component
import DataTableExtensions from 'react-data-table-component-extensions'; // Import DataTableExtensions component
import 'react-data-table-component-extensions/dist/index.css';
import axios from 'axios';

export default function Orders() {
   
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;



    let today = new Date();
    let dd = today.getDate();
    
    let mm = today.getMonth()+1; 
    let yyyy = today.getFullYear();
    if(dd<10) 
    {
        dd='0'+dd;
    } 
    
    if(mm<10) 
    {
        mm='0'+mm;
    } 
    today = yyyy+'-'+mm+'-'+dd;
    // today = '2022'+'-'+'06'+'-'+'21';
    const [todaydate, settodaydate] = useState(today)
    
    const [isload, setisload] = useState(true);
    const [data, setData] = useState([]);
    useEffect(() => {
        const API_PATH = `${SERVER_URL}orders.php`;
        const passval = {
            type: "receive",
            ref: sessionStorage.getItem('shipperref'),
            orderdate:todaydate
        };
         axios.post(API_PATH, passval)   
            .then(res => {
               console.log(' data is: ',res.data)
                setData(res.data)
                setisload(false);                
            })
            .catch((err) => {alert(2)
                console.log(err);
            }) 
    }, []);

  
    const [selectedRows, setSelectedRows] = useState([]);

    const handleCheckboxClick = (id) => {
        if (selectedRows.includes(id)) {
            
            setSelectedRows(selectedRows.filter(rowId => rowId !== id));
        } else {
            setSelectedRows([...selectedRows, id]);
        }
    };
    
    const handleSelectAll = (isChecked, rows) => {
     
        const enabledRows = rows.filter(row => !row.disabled);

    // Get IDs of enabled rows
    const ids = enabledRows.map(row => row.Awbno);

    setSelectedRows(isChecked ? ids : []);
   
    };

    // const [selectedRows, setSelectedRows] = useState([]);

    // const handleCheckboxClick = (id) => {
    //     // Handle checkbox click here, for example, add the id to selectedRows
    //     if (selectedRows.includes(id)) {
    //         setSelectedRows(selectedRows.filter(rowId => rowId !== id));
    //     } else {
    //         setSelectedRows([...selectedRows, id]);
    //     }
    // };

    // const handleSelectAll = (isChecked, rows) => {

    //     const enabledRows = rows.filter(row => !row.disabled);
    
    //     // Get IDs of enabled rows
    //     const ids = enabledRows.map(row => row.Awbno);
        
    //     // Update selectedRows state with enabled IDs
    //     setSelectedRows(isChecked ? ids : []);

    //     // const ids = rows.map(row => row.Awbno);
    //     // setSelectedRows(isChecked ? ids : []);
    // };
     
    const columns = [
        {
            cell: row => <input type="checkbox" checked={selectedRows.includes(row.Awbno)} onChange={() => handleCheckboxClick(row.Awbno)}  disabled={row.confirmed === 1} />,
            ignoreRowClick: true,
            allowOverflow: true,
            name: <input type="checkbox" checked={selectedRows.length === data.length} onChange={(e) => handleSelectAll(e.target.checked, data)}  disabled={false}/>,
        },
        {
            name: "AWB",
            selector: row => row.Awbno,
            sortable: true,
        },
        {
            name: "Consignee",
            selector: row => row.Consignee,
            sortable: true,
        },
        {
            name: "Destination",
            selector: row => row.Destination,
            sortable: true,
        },
        {
            name: "Shipperref",
            selector: row => row.Shipperref,
            sortable: true,
        },
    ];

    const tableData = {
        columns,
        data
    };

const [msg, setmsg] = useState('')
    function confirmShipments(){
        console.log('seletced: ',selectedRows);
        if(selectedRows.length>0){
        window.confirm("Are you sure to confirm receive ?")
        ?confirmships(selectedRows)
        :confirmships(0)
        }
        else{
            alert('Please choose shipments')
        }
    }

    const confirmships = async (val) => {
        if(val!=0){

            const API_PATH = `${SERVER_URL}orders.php`;
            const passval = {
                type: "confirm",
                values: selectedRows,
                store:1
            };
             axios.post(API_PATH, passval)   
                .then(res => {
                        if(res.data >0){
                            setmsg('selected shipments confirmed')
                        }else{
                            setmsg('selected shipments delivered or returned')
                        }    
                })
                .catch((err) => {
                    console.log(err);
                }) 

        }
    }

    const [searchdate, setdate] = useState('')
  console.log('date is',searchdate)

  function searchbyDate(){
    const API_PATH = `${SERVER_URL}orders.php`;
    settodaydate(searchdate)
    const passval = {
        type: "receive",
        ref: sessionStorage.getItem('shipperref'),
        orderdate:searchdate
    };
     axios.post(API_PATH, passval)   
        .then(res => {
           console.log(' data is: ',res.data)
            setData(res.data)
            setisload(false);                
        })
        .catch((err) => {alert(2)
            console.log(err);
        }) 
  }
    return (
        <div>
            <Header />
           
            <section className="page-section cta">
                <div className="container">
               
                    <div className="row">
                    
                        <div className="col-xl-9 mx-auto">
                            <div className="cta-inner bg-faded text-center rounded">
                           

                                <h2 className="section-heading mb-5">
                                    
                                    <span className="section-heading-lower">Orders</span>
                                    <span className="section-heading-upper">{todaydate}</span>
                                </h2>
                                <div className="row justify-content-end">
                <div className="col-md-3 d-flex justify-content-end">
                    <input type="date" onChange={(e)=>setdate(e.target.value)}/>
                </div>
                <div className="col-md-1 d-flex justify-content-end">
                    <button className='button' style={{padding: '1px 4px', margin: 0 }} onClick={searchbyDate}>search</button>
                </div>
                </div>

                                <DataTableExtensions {...tableData}>
                                    <DataTable
                                        columns={columns}
                                        data={data}
                                        noHeader
                                        defaultSortField="user"
                                        defaultSortAsc={true}
                                        pagination
                                        highlightOnHover
                                        dense
                                    />
                                </DataTableExtensions>

                            {data && data.length >0?(
  <button className='button' onClick={confirmShipments}>Confirm Receive</button>
                            ):null}
                              
                                <p style={{color:'green'}}>{msg}</p>
                            </div>
                         
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}
