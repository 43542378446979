import React from 'react'

export default function Footer() {
  return (
    <div>
   <footer className="footer text-faded text-center py-5">
    <div className="container"><p className="m-0 small">© 2024 TIME EXPRESS PARCELS | ALL RIGHTS RESERVED.</p></div>
  </footer>

<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js"></script>

<script src="js/scripts.js"></script>

</div>
  )
}
